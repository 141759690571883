<template>
  <div class="container content-sb">
    <div class="white-container">
      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
    <aside class="admin-nav">
      <button @click="FileManager" class="file-manager-btn">
        Файловый менеджер
      </button>
      <admin-nav v-for="(item, i) in navigation" :key="`navigation${i}`" :navigation="item"/>
    </aside>
    <FileManager v-if="statusFileManager" @closeFileManager="FileManager" />
  </div>
</template>

<script>
import AdminNav from '@/components/Admin/AdminNav'
import FileManager from '@/components/Admin/FileManager'
export default {
  name: 'index',
  data () {
    return {
      navigation: [],
      statusFileManager: false
    }
  },
  methods: {
    async getInfoAdmin () {
      const res = await fetch(`${this.$store.getters.getUrlApi}api/user/me`, {
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
      if (!res.ok) {
        this.$noty.error('Вам необходимо перезайти в свой аккаунт')
        localStorage.removeItem('token')
        this.$router.push('/')
      }
      await res.json()
        .then(res => {
          if (res.data.role === 'Администратор') {
            const pages = [
              {
                title: 'Главная',
                links: [
                  {
                    title: 'Информационные блоки - Горячая линия',
                    link: '/admin/hotline_info'
                  },
                  {
                    title: 'Английская версия',
                    link: '/admin/eng_flag'
                  },
                  {
                    title: 'Динамические разделы',
                    link: '/admin/section'
                  },
                  {
                    title: 'Динамические страницы',
                    link: '/admin/page'
                  }
                ]
              },
              {
                title: 'Новости',
                links: [
                  {
                    title: 'Новости',
                    link: '/admin/news'
                  },
                  {
                    title: 'Анонсы',
                    link: '/admin/announcement'
                  },
                  // {
                  //   title: 'Комментарии',
                  //   link: '/admin'
                  // },
                  {
                    title: 'Медиаматериалы',
                    link: '/admin/media'
                  },
                  {
                    title: 'Прямые трансляции',
                    link: '/admin/live_stream'
                  },
                  {
                    title: 'Блоги',
                    link: '/admin/blogs'
                  },
                  {
                    title: 'Тэги на главной',
                    link: '/admin/index_tags'
                  }
                ]
              },
              {
                title: 'О палате',
                links: [
                  {
                    title: 'О палате (Англ.)',
                    link: '/admin/about_the_ward'
                  },
                  {
                    title: 'Члены ОПРФ',
                    link: '/admin/members'
                  },
                  {
                    title: 'Составы',
                    link: '/admin/convocation_list'
                  },
                  {
                    title: 'Подразделения',
                    link: '/admin/unit'
                  },
                  {
                    title: 'Аппарат',
                    link: '/admin/apparatus'
                  },
                  {
                    title: 'Категории аппарата',
                    link: '/admin/apparatus_category'
                  },
                  {
                    title: 'Партнеры',
                    link: '/admin/partners'
                  },
                  {
                    title: 'Противодействие коррупции',
                    link: '/admin/corruption'
                  },
                  // {
                  //   title: 'Структура общественной палаты',
                  //   link: '/admin/structure'
                  // },
                  {
                    title: 'Нормативная база',
                    link: '/admin/normative_base'
                  },
                  {
                    title: 'Рекомендации структуры',
                    link: '/admin/recommendation'
                  }
                ]
              },
              {
                title: 'Деятельность',
                links: [
                  {
                    title: 'Документы',
                    link: '/admin/documents'
                  },
                  {
                    title: 'Законопроекты',
                    link: '/admin/bill_draft'
                  },
                  {
                    title: 'Экспертные заключения - документы',
                    link: '/admin/expert_opinions'
                  },
                  {
                    title: 'План экспертной деятельности - документы',
                    link: '/admin/activity_plan'
                  },
                  {
                    title: 'Актуальные темы',
                    link: '/admin/topic'
                  },
                  {
                    title: 'Проекты',
                    link: '/admin/project'
                  },
                  {
                    title: 'Общественные палаты регионов по округам',
                    link: '/admin/info_advice'
                  },
                  {
                    title: 'Общественные палаты регионов - документы',
                    link: '/admin/public_doc'
                  },
                  {
                    title: 'Информация об образованных ОНК в субъектах РФ',
                    link: '/admin/info_nko'
                  },
                  {
                    title: 'Изменение в составе ОНК',
                    link: '/admin/commissionUpdate'
                  },
                  {
                    title: 'ОНК – документы',
                    link: '/admin/onk_document'
                  },
                  {
                    title: 'ОНК – партнеры',
                    link: '/admin/nko_partners'
                  },
                  {
                    title: 'Фомирование общественных советов',
                    link: '/admin/formation_councils'
                  },
                  {
                    title: 'Кандидату в общественный совет',
                    link: '/admin/candidate_public_council'
                  },
                  {
                    title: 'Общественные советы – документы',
                    link: '/admin/community_councils_file'
                  },
                  {
                    title: 'Общественные советы при ФОИВ',
                    link: '/admin/public_council_banner'
                  },
                  {
                    title: 'Часто задаваемые вопросы',
                    link: '/admin/asked_questions'
                  },
                  // Контактная информацию отдела по работе с ОНК
                  {
                    title: 'Контактная информацию отдела по работе с ОНК',
                    link: '/admin/contact_information_onk '
                  }
                ]
              },
              {
                title: 'Связаться',
                links: [
                  {
                    title: 'Опросы граждан',
                    link: '/admin/polls'
                  },
                  {
                    title: 'Аккредитация СМИ',
                    link: '/admin/accreditation'
                  },
                  {
                    title: 'Контакты',
                    link: '/admin/contacts'
                  },
                  {
                    title: 'Контакты - горячая линия',
                    link: '/admin/feedback_hotline'
                  },
                  {
                    title: 'Контакт для СМИ',
                    link: '/admin/for_media_contacts'
                  },
                  {
                    title: 'Соц. сети',
                    link: '/admin/social_network'
                  },
                  {
                    title: 'Информационные блоки',
                    link: '/admin/connect_contacts'
                  }
                ]
              },
              {
                title: 'Пользователи',
                links: [
                  {
                    title: 'Все пользователи',
                    link: '/admin/users'
                  },
                  {
                    title: 'Создать пользователя',
                    link: '/admin/users/create_user?type=create'
                  }
                ]
              },
              {
                title: 'Справочные данные',
                links: [
                  {
                    title: 'Логи',
                    link: '/admin/logs'
                  },
                  {
                    title: 'Страны',
                    link: '/admin/country'
                  },
                  {
                    title: 'Должности',
                    link: '/admin/positions'
                  },
                  {
                    title: 'Кем утвержден',
                    link: '/admin/inclusion_ground'
                  },
                  {
                    title: 'Секции коррупции',
                    link: '/admin/corruption_section'
                  },
                  {
                    title: 'Группы коррупции',
                    link: '/admin/corruption_group'
                  },
                  {
                    title: 'Тип подразделения',
                    link: '/admin/unit_type'
                  },
                  {
                    title: 'Рубрики',
                    link: '/admin/rubric'
                  },
                  {
                    title: 'Субъекты',
                    link: '/admin/subject'
                  },
                  {
                    title: 'Статус законопроекта',
                    link: '/admin/status_bill'
                  }
                ]
              },
              {
                title: 'E-mail маркетинг',
                links: [
                  {
                    title: 'E-mail маркетинг',
                    link: '/admin/email_market'
                  }
                ]
              },
              {
                title: 'Формы обратной связи',
                links: [
                  {
                    title: 'Подписка на рассылку',
                    link: '/admin/email_subscription'
                  }
                ]
              },
              {
                title: 'Комментарии',
                links: [
                  {
                    title: 'Новости',
                    link: '/admin/news_comments'
                  },
                  {
                    title: 'Блоги',
                    link: '/admin/comments_blogs'
                  },
                  {
                    title: 'Законопроекты',
                    link: '/admin/comments_bill'
                  },
                  {
                    title: 'Актуальная тема',
                    link: '/admin/comments_topical'
                  }
                ]
              },
              {
                title: 'Форум "Сообщество"',
                links: [
                  {
                    title: 'Форумы по годам',
                    link: '/admin/forum'
                  },
                  {
                    title: 'Форумы в городах',
                    link: '/admin/forum_final'
                  }
                ]
              },
              {
                title: 'Мой проект — моей стране',
                links: [
                  {
                    title: 'Периоды конкурса',
                    link: '/admin/project_appeal_periods'
                  },
                  {
                    title: 'Принципы конкруса',
                    link: '/admin/project_principles'
                  },
                  {
                    title: 'Дополнительные материалы',
                    link: '/admin/additional_materials'
                  },
                  {
                    title: 'Основная информация',
                    link: '/admin/main_information'
                  },
                  {
                    title: 'Текст подачи заявки',
                    link: '/admin/project_appeal_texts'
                  },
                  {
                    title: 'Ссылка в заголовке',
                    link: '/admin/project_title_links'
                  },
                  {
                    title: 'Номинации',
                    link: '/admin/nomination'
                  },
                  {
                    title: 'Ключевые даты',
                    link: '/admin/key_dates'
                  },
                  {
                    title: 'Оценка проекта',
                    link: '/admin/project_evaluation'
                  },
                  {
                    title: 'Контактный e-mail',
                    link: '/admin/project_email'
                  }
                ]
              }
            ]
            this.navigation = pages
          } else if (res.data.role === 'Контент менеджер') {
            const pages = [
              {
                title: 'Новости',
                links: [
                  {
                    title: 'Новости',
                    link: '/admin/news'
                  },
                  {
                    title: 'Анонсы',
                    link: '/admin/announcement'
                  },
                  // {
                  //   title: 'Комментарии',
                  //   link: '/admin'
                  // },
                  {
                    title: 'Медиаматериалы',
                    link: '/admin/media'
                  },
                  {
                    title: 'Прямые трансляции',
                    link: '/admin/live_stream'
                  },
                  {
                    title: 'Блоги',
                    link: '/admin/blogs'
                  }
                ]
              }
            ]
            this.navigation = pages
          }
        })
    },
    FileManager () {
      this.statusFileManager = !this.statusFileManager
    }
  },
  mounted () {
    this.getInfoAdmin()
  },
  components: {
    AdminNav,
    FileManager
  }
}
</script>

<style scoped lang="scss">
.file-manager-btn {
  padding: 1.56rem 2rem;
  background: #fff;
  border: 1px solid #E5E8EE;
  box-sizing: border-box;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
  border-radius: 8px;
  margin-bottom: 1rem;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.container{
  margin-top: 2.5rem;
}
.white-container{
  margin-left: -2.5rem;
  width: 61.56rem;
}

.admin-nav{
  width: 24rem;
}
</style>

<style lang="scss">
.admin-title{
  font-weight: 600;
  font-size: 1.12rem;
  color: #1F3245;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-bottom: 1.87rem;
}
</style>
