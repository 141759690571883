<template>
  <section class="file-manager">
    <div class="file-manager__container">
      <header>
        <h2>Файловый менеджер</h2>
        <svg @click="$emit('closeFileManager')" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.632812" y="5.85156" width="2" height="8" rx="1" transform="rotate(-45 0.632812 5.85156)" fill="#5A79A8"/>
          <rect x="10.5312" y="4.4375" width="2" height="8" rx="1" transform="rotate(45 10.5312 4.4375)" fill="#5A79A8"/>
          <rect x="11.9453" y="14.3359" width="2" height="8" rx="1" transform="rotate(135 11.9453 14.3359)" fill="#5A79A8"/>
          <rect x="2.04688" y="15.75" width="2" height="8" rx="1" transform="rotate(-135 2.04688 15.75)" fill="#5A79A8"/>
        </svg>
      </header>
      <tricolor-line class="card_containet__tricolor"/>
      <div class="file-manager__route">
        <p v-for="(item, i) in route" @click="changeRoute(i)" :key="`route${item}${i}`">{{ item === '/' ? 'Главная' : '/'+ item }}</p>
        <div class="search_container">
          <input type="text" class="input blue" v-model="search">
          <button class="btn-blue" @click="getData">
            Найти
          </button>
        </div>
      </div>
      <main>
          <form
                @submit.prevent="createFolder"
                class="file-manager__block">
          <p class="file-manager__text"><b>Добавить папку</b></p>
          <input required v-model="titleFolder" class="file-manager__text-input" type="text" maxlength="255" placeholder="введите название">
          <input class="btn-blue" type="submit" value="создать">
        </form>
          <form
                ref="files"
                @submit.prevent="AddFiles"
                class="file-manager__block">
          <p class="file-manager__text"><b>Добавить документы</b></p>
          <input required  class="file-manager__text-input" type="file" multiple name="files[]">
          <input class="btn-blue" type="submit" value="Загрузить">
        </form>
          <div
            v-for="folder in folders"
            @click="routeFileManager(`${folder.title}`)"
            :key="`folder${folder.title}`"
            class="file-manager__block file-manager__block-folder">
            <svg class="file-manager__main-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 468.293 468.293" style="enable-background:new 0 0 468.293 468.293;" xml:space="preserve">
              <path style="fill:#246CB7;" d="M206.049,72.574V55.559c0-10.345-8.386-18.732-18.732-18.732H18.732C8.386,36.827,0,45.213,0,55.559
                v110.248h468.293v-62.013c0-10.345-8.386-18.732-18.732-18.732H218.537C211.64,85.062,206.049,79.471,206.049,72.574z"/>
                            <path style="fill:#4092e5;" d="M443.317,431.466H24.976C11.182,431.466,0,420.284,0,406.49V161.35h206.748
                c5.268,0,10.293-2.218,13.842-6.111l23.013-25.241c3.549-3.893,8.574-6.111,13.842-6.111h210.848V406.49
                C468.293,420.284,457.111,431.466,443.317,431.466z"/>
            </svg>
            <p v-trim="3" class="file-manager__text" :title="folder.title">{{ folder.title }}</p>
            <svg class="file-manager__cross-del" @click.stop="delFile($event, folder.title)" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.632812" y="5.85156" width="2" height="8" rx="1" transform="rotate(-45 0.632812 5.85156)" fill="red"/>
              <rect x="10.5312" y="4.4375" width="2" height="8" rx="1" transform="rotate(45 10.5312 4.4375)" fill="red"/>
              <rect x="11.9453" y="14.3359" width="2" height="8" rx="1" transform="rotate(135 11.9453 14.3359)" fill="red"/>
              <rect x="2.04688" y="15.75" width="2" height="8" rx="1" transform="rotate(-135 2.04688 15.75)" fill="red"/>
            </svg>
          </div>
          <div v-for="(item, i) in files" :key="`files${item.title}`" class="file-manager__block">
            <svg class="file-manager__main-icon"  viewBox="0 0 56 74" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M54.7656 18.5578C55.2344 19.0533 55.5 19.7223 55.5 20.4243V71.3571C55.5 72.819 54.3828 74 53 74H3C1.61719 74 0.5 72.819 0.5 71.3571V2.64286C0.5 1.18103 1.61719 0 3 0H36.1797C36.8438 0 37.4844 0.280804 37.9531 0.776339L54.7656 18.5578V18.5578ZM49.7344 21.6384L35.0312 6.09509V21.6384H49.7344ZM13 34.5223C12.8342 34.5223 12.6753 34.5919 12.5581 34.7158C12.4408 34.8397 12.375 35.0078 12.375 35.183V39.1473C12.375 39.3226 12.4408 39.4906 12.5581 39.6145C12.6753 39.7384 12.8342 39.808 13 39.808H43C43.1658 39.808 43.3247 39.7384 43.4419 39.6145C43.5592 39.4906 43.625 39.3226 43.625 39.1473V35.183C43.625 35.0078 43.5592 34.8397 43.4419 34.7158C43.3247 34.5919 43.1658 34.5223 43 34.5223H13ZM13 45.7545C12.8342 45.7545 12.6753 45.8241 12.5581 45.948C12.4408 46.0719 12.375 46.2399 12.375 46.4152V50.3795C12.375 50.5547 12.4408 50.7228 12.5581 50.8467C12.6753 50.9706 12.8342 51.0402 13 51.0402H27.375C27.5408 51.0402 27.6997 50.9706 27.8169 50.8467C27.9342 50.7228 28 50.5547 28 50.3795V46.4152C28 46.2399 27.9342 46.0719 27.8169 45.948C27.6997 45.8241 27.5408 45.7545 27.375 45.7545H13Z" fill="#246CB7"/>
            </svg>
            <p class="file-manager__text">{{ item.title }}</p>
            <svg class="file-manager__cross-del" @click.stop="delFile($event, item.title)" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.632812" y="5.85156" width="2" height="8" rx="1" transform="rotate(-45 0.632812 5.85156)" fill="red"/>
              <rect x="10.5312" y="4.4375" width="2" height="8" rx="1" transform="rotate(45 10.5312 4.4375)" fill="red"/>
              <rect x="11.9453" y="14.3359" width="2" height="8" rx="1" transform="rotate(135 11.9453 14.3359)" fill="red"/>
              <rect x="2.04688" y="15.75" width="2" height="8" rx="1" transform="rotate(-135 2.04688 15.75)" fill="red"/>
            </svg>
            <div class="file-manager__wrapper-mini-icon">
              <input type="text" :value="item.url" ref="src" class="hidden">
              <svg @click="copyUrl(i)" class="file-manager__mini-icon"  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                     viewBox="0 0 699.428 699.428" style="enable-background:new 0 0 699.428 699.428;"
                     xml:space="preserve">
                  <g>
                  <g id="_x33__21_">
                    <g>
                      <path d="M502.714,0c-2.71,0-262.286,0-262.286,0C194.178,0,153,42.425,153,87.429l-25.267,0.59
                        c-46.228,0-84.019,41.834-84.019,86.838V612c0,45.004,41.179,87.428,87.429,87.428H459c46.249,0,87.428-42.424,87.428-87.428
                        h21.857c46.25,0,87.429-42.424,87.429-87.428v-349.19L502.714,0z M459,655.715H131.143c-22.95,0-43.714-21.441-43.714-43.715
                        V174.857c0-22.272,18.688-42.993,41.638-42.993L153,131.143v393.429C153,569.576,194.178,612,240.428,612h262.286
                        C502.714,634.273,481.949,655.715,459,655.715z M612,524.572c0,22.271-20.765,43.713-43.715,43.713H240.428
                        c-22.95,0-43.714-21.441-43.714-43.713V87.429c0-22.272,20.764-43.714,43.714-43.714H459c-0.351,50.337,0,87.975,0,87.975
                        c0,45.419,40.872,86.882,87.428,86.882c0,0,23.213,0,65.572,0V524.572z M546.428,174.857c-23.277,0-43.714-42.293-43.714-64.981
                        c0,0,0-22.994,0-65.484v-0.044L612,174.857H546.428z M502.714,306.394H306c-12.065,0-21.857,9.77-21.857,21.835
                        c0,12.065,9.792,21.835,21.857,21.835h196.714c12.065,0,21.857-9.771,21.857-21.835
                        C524.571,316.164,514.779,306.394,502.714,306.394z M502.714,415.57H306c-12.065,0-21.857,9.77-21.857,21.834
                        c0,12.066,9.792,21.836,21.857,21.836h196.714c12.065,0,21.857-9.77,21.857-21.836C524.571,425.34,514.779,415.57,502.714,415.57
                        z"/>
                    </g>
                  </g>
                </g>
                </svg>
              <a title="открыть файл" target="_blank" :href="item.url">
                <svg class="file-manager__mini-icon"  xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                     viewBox="0 0 18.453 18.453" style="enable-background:new 0 0 18.453 18.453;" xml:space="preserve">
                <g>
                  <g>
                    <rect x="2.711" y="4.058" style="fill:#246CB7;" width="8.23" height="1.334"/>
                    <path style="fill:#246CB7;" d="M14.972,14.088c0.638-1.127,0.453-2.563-0.475-3.49c-0.549-0.549-1.279-0.852-2.058-0.852
                      c-0.779,0-1.51,0.303-2.059,0.852s-0.852,1.279-0.852,2.059c0,0.777,0.303,1.508,0.852,2.059c0.549,0.547,1.279,0.85,2.057,0.85
                      c0.507,0,0.998-0.129,1.434-0.375l3.262,3.262l1.101-1.102L14.972,14.088z M13.664,13.881c-0.652,0.652-1.796,0.652-2.448,0
                      c-0.675-0.676-0.675-1.773,0-2.449c0.326-0.326,0.762-0.506,1.225-0.506s0.897,0.18,1.224,0.506s0.507,0.762,0.507,1.225
                      S13.991,13.554,13.664,13.881z"/>
                    <path style="fill:#246CB7;" d="M13.332,16.3H1.857c-0.182,0-0.329-0.148-0.329-0.328V1.638c0-0.182,0.147-0.329,0.329-0.329
                      h11.475c0.182,0,0.328,0.147,0.328,0.329V8.95c0.475,0.104,0.918,0.307,1.31,0.597V1.638C14.97,0.735,14.236,0,13.332,0H1.857
                      C0.954,0,0.219,0.735,0.219,1.638v14.334c0,0.902,0.735,1.637,1.638,1.637h11.475c0.685,0,1.009-0.162,1.253-0.76l-0.594-0.594
                      C13.772,16.347,13.426,16.3,13.332,16.3z"/>
                    <rect x="2.711" y="7.818" style="fill:#246CB7;" width="8.23" height="1.334"/>
                  </g>
                </g>
              </svg>
              </a>
            </div>
          </div>
      </main>
    </div>
  </section>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'FileManager',
  components: {
    TricolorLine
  },
  data () {
    return {
      files: [],
      folders: [],
      route: ['/'],
      titleFolder: '',
      search: ''
    }
  },
  methods: {
    async getData () {
      let url = `${this.$store.getters.getUrlApi}api/admin/store?path=${this.getUrlFileManager}`
      if (this.search) {
        url = `${this.$store.getters.getUrlApi}api/admin/store/find?title=${this.search}`
      }
      const res = await fetch(url)
      const data = await res.json()
      this.files = await data.data.files
      this.folders = await data.data.folders
    },
    routeFileManager (path) {
      this.route.push(path)
    },
    changeRoute (i) {
      this.route.splice(i + 1, this.route.length - i)
    },
    copyUrl (i) {
      const src = this.$refs.src[i]
      src.classList.remove('hidden')
      src.select()
      document.execCommand('copy')
      src.classList.add('hidden')
      this.$noty.success('Ссылка скопирована в буфер обмена')
    },
    async delFile (event, title) {
      let urlFile = ''
      if (this.getUrlFileManager.length === 1) {
        urlFile = `${this.getUrlFileManager}${title}`
      } else {
        urlFile = `${this.getUrlFileManager}/${title}`
        console.log(urlFile)
      }
      const consent = confirm('Вы уверены, чтобы хотите удалить ?')
      if (consent) {
        const res = await fetch(`${this.$store.getters.getUrlApi}api/admin/store/delete?path=${urlFile}`, {
          method: 'DELETE',
          headers: {
            Authorization: localStorage.getItem('token')
          }
        })
        if (res.ok) {
          this.$noty.success('Элемент удален')
          this.getData(this.route)
        } else {
          this.$noty.error('Произошла ошибка')
        }
      } else {
        this.$noty.error('Удаление отменено')
      }
    },
    async createFolder () {
      const formData = new FormData()
      formData.append('title', this.titleFolder)
      formData.append('path', this.getUrlFileManager)
      const res = await fetch(`${this.$store.getters.getUrlApi}api/admin/store/make`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: formData
      })
      if (res.ok) {
        this.$noty.success(`Папка ${this.titleFolder} создана`)
        this.titleFolder = ''
        this.getData()
      } else {
        this.$noty.error('Произошла ошибка')
      }
    },
    async AddFiles () {
      const formData = new FormData(this.$refs.files)
      formData.append('path', this.getUrlFileManager)
      const res = await fetch(`${this.$store.getters.getUrlApi}api/admin/store/upload`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        },
        body: formData
      })
      if (res.ok) {
        this.$noty.success('Файлы загружены')
        this.getData(this.route)
      } else {
        this.$noty.error('Произошла ошибка')
      }
    }
  },
  computed: {
    getUrlFileManager () {
      return this.route.join().length === 1 ? this.route.join() : this.route.join('/').slice(1)
    }
  },
  watch: {
    route (value) {
      this.getData()
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped lang="scss">
.file-manager {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  background: rgba(0,0,0,.8);
  z-index: 100;
  &__main-icon{
    width: 4rem;
    margin-bottom: .5rem;
  }
  &__cross-del {
    width: 1rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
  &__block {
    position: relative;
    width: 20rem;
    height: 15rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
    border-radius: 8px;
    border: 1px solid #E5E8EE;
    margin-right: 1rem;
    margin-bottom: 1rem;
    p {
      text-align: center;
    }
  }
  &__block-folder {
    cursor: pointer;
  }
  &__text-input {
    width: 100%;
    border: 1px solid #E5E8EE;
    padding: .5rem;
    margin: 2rem 0;
  }
  &__mini-icon {
    width: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
    fill: #246CB7;
    &:last-child{
      margin-right: 0;
    }
  }
  &__text {
    font-weight: 600;
    line-height: 157%;
    color: #1F3245;
    opacity: 0.9;
  }
  &__wrapper-mini-icon {
    margin-top: 2rem;
  }
}
.file-manager__route {
  padding: 0 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  p {
    cursor: pointer;
  }
  .search_container {
    margin-left: auto;
    input {
      width: 15rem;
      margin-right: 1rem;
    }
  }
}

.file-manager__container {
  width: 90vw;
  height: 90vh;
  background: #fff;
  margin: 5vh auto 0 auto;
  border-radius: 0.5rem;
  padding: 2.5rem 0;
  overflow: hidden;
}
header {
  padding: 0 2.5rem;
  display: flex;
  justify-content: space-between;
  h2 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 127%;
    opacity: 1;
    color: #1F3245;
  }
  svg {
    width: 1rem;
    cursor: pointer;
  }
}
main {
  padding: 0 2.5rem 2.5rem 2.5rem;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 65vh;
}
.hidden {
  display: none;
}
</style>
