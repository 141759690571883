<template>
<div class="admin-nav" v-if="navigation.links.length">
  <header @click="statusList = !statusList" class="admin-nav__header">
    <h4>{{ navigation.title }}</h4>
    <svg viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="1.48926" width="2.10606" height="8.42426" rx="1" transform="rotate(-45 0 1.48926)" fill="#9D9D9D"/>
      <rect x="10.4243" width="2.10606" height="8.42426" rx="1" transform="rotate(45 10.4243 0)" fill="#9D9D9D"/>
    </svg>
  </header>
  <div v-show-slide="statusList">
    <ul>
      <li v-for="(item ,i) in navigation.links" :key="`navigation.links${i}`">
        <router-link :to="item.link">{{ item.title }}</router-link>
      </li>
    </ul>
  </div>

</div>
</template>

<script>
export default {
  name: 'AdminNav',
  data () {
    return {
      statusList: false
    }
  },
  props: {
    navigation: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

.admin-nav{
  width: 24rem;
  padding: 1.56rem 2rem;
  background: #fff;
  border: 1px solid #E5E8EE;
  box-sizing: border-box;
  box-shadow: 0px 15px 25px rgba(189, 205, 221, 0.35);
  border-radius: 8px;
  margin-bottom: 1rem;
}
.admin-nav__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  h4{
    font-weight: 600;
    font-size: 0.875rem;
    color: #1F3245;
  }
  svg{
    width: 0.75rem;
  }
}

ul{
  border-top: 1px solid #000000;
  margin-top: 1.25rem;
  padding-top: 1rem;
  li{
    a{
      display: block;
      margin-bottom: 0.62rem;
      font-size: 0.75rem;
      color: #003E78;
    }
  }
}
</style>
